import React from 'react';

import Copyrights from 'components/Footer/Copyrights';
import Widgets from 'components/Footer/Widgets';

const Footer = ({ site }) => {
  return (
    <footer>
      <Widgets />
      <Copyrights site={site} />
    </footer>
  );
};

export default Footer;
