// extracted by mini-css-extract-plugin
export var container = "SearchOverlay-module--container--3t5oV";
export var isOpen = "SearchOverlay-module--isOpen--1zIcQ";
export var header = "SearchOverlay-module--header--1vqV4";
export var logo = "SearchOverlay-module--logo--1vQDN";
export var close = "SearchOverlay-module--close--IST9k";
export var main = "SearchOverlay-module--main--250hb";
export var form = "SearchOverlay-module--form--34rrz";
export var label = "SearchOverlay-module--label--3NHaN";
export var search = "SearchOverlay-module--search--1nYSr";
export var searchButton = "SearchOverlay-module--search-button--3-9jp";
export var popularContainer = "SearchOverlay-module--popularContainer--221SB";
export var popularTitle = "SearchOverlay-module--popularTitle--2FKLx";
export var popularLinks = "SearchOverlay-module--popularLinks--C9bUA";
export var popularLink = "SearchOverlay-module--popularLink--3Gp_X";