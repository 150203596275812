import addToMailchimp from 'gatsby-plugin-mailchimp';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import {
  section,
  container,
  title,
  text,
  form,
  inputContainer,
  input,
  btn,
  successText,
} from './Newsletter.module.scss';

const Newsletter = () => {
  const [response, setResponse] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async ({ email }) => {
    const response = await addToMailchimp(email);
    setResponse(response);
  };
  return (
    <div className={section}>
      <div className={container}>
        <h2 className={title}>Newsletter</h2>
        <p className={text}>
          Μπείτε στη παρέα του MINOERA, για να ενημερώνεστε άμεσα με νέα και
          προσφορές
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className={form}>
          <div className={inputContainer}>
            <input
              type="text"
              name="email"
              className={input}
              aria-label="Newsletter email"
              placeholder="Διεύθυνση email"
              ref={register({
                required: 'Το πεδίο είναι υποχρεωτικό',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Λάθος μορφή email',
                },
              })}
            />
            <button className={btn} type="submit">
              ΕΓΓΡΑΦΗ
            </button>
          </div>
          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
          {response && response.result === 'success' && (
            <p
              className={successText}
              dangerouslySetInnerHTML={{ __html: response.msg }}
            />
          )}
          {response && response.result === 'error' && (
            <ErrorMessage>
              <span dangerouslySetInnerHTML={{ __html: response.msg }} />
            </ErrorMessage>
          )}
        </form>
      </div>
    </div>
  );
};

export default Newsletter;
