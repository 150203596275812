/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { useLocation } from '@reach/router';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import PropTypes from 'prop-types';
import React from 'react';
import CookieConsent from 'react-cookie-consent';

import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import CustomCreations from 'components/Icons/Project/CustomCreations';
import Newsletter from 'components/Newsletter/Newsletter';
import PromoSectionCentered from 'components/PromoSection/PromoSectionCentered';

import {
  site,
  fullWidthContent,
  siteContent,
  blockContentCentered,
} from './Layout.module.scss';
import { btnLinkDark } from 'styles/components/links.module.scss';
import {
  secondaryHeaderDark,
  mainDescriptionDark,
} from 'styles/components/typography.module.scss';

const barStyle = {
  alignItems: 'center',
  background: '#fff',
  color: ' #757575',
  padding: '0 1.5rem',
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
};
const btnStyle = {
  background: '#c5a557',
  color: '#000',
  padding: '1rem 2rem',
  fontWeight: '900',
};
const contentStyle = {
  maxWidth: '100%',
};
const pStyle = {
  marginBottom: '0',
  fontSize: '0.875rem',
};

const Layout = ({ children, fullWidth = false, hasCustomSection = true }) => {
  const location = useLocation();
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          brand
          site
        }
      }
    }
  `);

  return (
    <div className={site}>
      <Header siteBrand={data.site.siteMetadata.brand} />
      <main className={fullWidth ? fullWidthContent : siteContent}>
        {children}
      </main>
      {hasCustomSection ? (
        <PromoSectionCentered>
          <div className={blockContentCentered}>
            <CustomCreations
              style={{ marginBottom: '1rem', maxWidth: '28.5rem' }}
              width="100%"
            />
            <h2
              style={{ marginBottom: '3rem' }}
              className={secondaryHeaderDark}
            >
              ΕΙΔΙΚΕΣ ΠΑΡΑΓΓΕΛΙΕΣ
            </h2>
            <p
              style={{ textAlign: 'center', marginBottom: '3.5rem' }}
              className={mainDescriptionDark}
            >
              Δώστε μας τις δικές σας οδηγίες, για να κάνετε το κόσμημα που
              επιλέξατε απόλυτα προσωπικό!
            </p>
            <Link className={btnLinkDark} to="/custom-creations">
              ΕΙΔΙΚΗ ΠΑΡΑΓΓΕΛΙΑ
            </Link>
          </div>
        </PromoSectionCentered>
      ) : null}
      <PromoSectionCentered>
        <Newsletter />
      </PromoSectionCentered>
      <CookieConsent
        buttonText="ΑΠΟΔΕΧΟΜΑΙ"
        cookieName="gdpr-acceptance"
        style={barStyle}
        buttonStyle={btnStyle}
        contentStyle={contentStyle}
        onAccept={() => {
          initializeAndTrack(location);
        }}
      >
        <p style={pStyle}>
          Η ιστοσελίδα <b>minoera.com</b> χρησιμοποιεί cookies για να βελτιώνει
          διαρκώς την εμπειρία των χρηστών του.
        </p>
      </CookieConsent>
      <Footer site={data.site.siteMetadata.site} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
