// extracted by mini-css-extract-plugin
export var nav = "Menu-module--nav--27MvI";
export var navList = "Menu-module--nav-list--1GSDA";
export var open = "Menu-module--open--2Xi8s";
export var menuContainer = "Menu-module--menuContainer--1Xgm8";
export var listItem = "Menu-module--listItem--1PSZw";
export var submenu = "Menu-module--submenu--1uQYo";
export var link = "Menu-module--link--3o52_";
export var submenuOpen = "Menu-module--submenuOpen--sS5Go";
export var submenuItem = "Menu-module--submenuItem--2lGOK";
export var submenuIcon = "Menu-module--submenu-icon--225QC";
export var submenuIconToggle = "Menu-module--submenu-icon-toggle--13AoZ";
export var iconsList = "Menu-module--icons-list--1dODT";
export var icon = "Menu-module--icon--1PVLS";
export var socialContainer = "Menu-module--socialContainer--11nl1";
export var logo = "Menu-module--logo--2hG70";
export var hideDesktop = "Menu-module--hideDesktop--1RY4r";