import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { FaPinterest } from 'react-icons/fa';

import IconText from 'components/IconText/IconText';
import DeliveryTruck from 'components/Icons/DeliveryTruck';
import Fb from 'components/Icons/Fb';
import Insta from 'components/Icons/Insta';

import useCart from 'hooks/useCart';

import * as styles from './Upper.module.scss';
import { linkUnderlined } from 'styles/components/links.module.scss';

const query = graphql`
  query UpperHeader {
    site {
      siteMetadata {
        title
        fb
        instagram
        pinterest
      }
    }
  }
`;

const Upper = () => {
  const {
    site: { siteMetadata: info },
  } = useStaticQuery(query);
  const { totals } = useCart();
  const freeTransfer =
    totals.priceWithoutFee && totals.priceWithoutFee.split('.')[0];
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.links}>
          <Link className={linkUnderlined} to="/etaireia">
            Η ΕΤΑΙΡΕΙΑ
          </Link>
          <span className={styles.dot}>&middot;</span>
          <Link className={linkUnderlined} to="/epikoinonia">
            ΕΠΙΚΟΙΝΩΝΙΑ
          </Link>
        </div>
        <IconText
          icon={<DeliveryTruck fill="#af9042" />}
          text={`ΔΩΡΕΑΝ ΜΕΤΑΦΟΡΙΚΑ ΓΙΑ ΠΑΡΑΓΓΕΛΙΕΣ ΑΝΩ ΤΩΝ ${
            freeTransfer || '99'
          }€`}
          textSize="small"
        />
        <div className={styles.socialContainer}>
          <a
            href={info.fb}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
            aria-label={`${info.title} facebook page`}
          >
            <Fb fill={'#fff'} />
          </a>
          <a
            href={info.instagram}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
            aria-label={`${info.title} instagram page`}
          >
            <Insta fill={'#fff'} />
          </a>
          <a
            href={info.pinterest}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
            aria-label={`${info.title} pinterest`}
          >
            <FaPinterest fill={'#fff'} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Upper;
