import useSWR from 'swr';

import fetcher from 'utilities/fetcher';

export default function useData(url, options) {
  const { data, error } = useSWR(url, fetcher, options);
  const loading = !data && !error;
  return {
    loading,
    error,
    data,
  };
}
