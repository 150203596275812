import React, { useEffect } from 'react';

import CartOverlay from 'components/CartOverlay/CartOverlay';
import ShoppingBag from 'components/Icons/Project/ShoppingBag';

import { useCartContext } from 'hooks/useCartContext';
import useToggle from 'hooks/useToggle';

import { container, notification } from './MenuCart.module.scss';
import { menuIconBtn } from 'styles/components/buttons.module.scss';

const MenuCart = () => {
  const { totals } = useCartContext();
  const { value, toggle } = useToggle();

  useEffect(() => {
    value
      ? document.documentElement.setAttribute('data-is-dialog-open', 'true')
      : document.documentElement.removeAttribute('data-is-dialog-open');
    return () => {
      document.documentElement.removeAttribute('data-is-dialog-open');
    };
  }, [value]);

  return (
    <div className={container}>
      <button
        onClick={toggle}
        className={menuIconBtn}
        aria-label="Αναζήτησε ρούχα, ενδύματα, κοσμήματα, υποδήματα"
      >
        <ShoppingBag />
        {totals && totals.quantity > 0 && (
          <span className={notification}>{totals.quantity}</span>
        )}
      </button>
      <CartOverlay isOpen={value} close={toggle} />
    </div>
  );
};

export default MenuCart;
