import { Link, navigate } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { FiX, FiSearch } from 'react-icons/fi';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Logo from 'components/Icons/LogoCentered';

import useData from 'data/useData';

import * as styles from './SearchOverlay.module.scss';

const defaultSearches = [
  { term: 'Σταυρός' },
  { term: 'Αμπράκαμος' },
  { term: 'Μαργαριτάρια' },
  { term: 'Μαχαίρι' },
  { term: 'Βραχιόλια' },
  { term: 'Κιουστέκια' },
];

const SearchOverlay = ({ close, isOpen }) => {
  const { register, errors, handleSubmit } = useForm();
  const { data: terms } = useData(isOpen ? `/search-terms` : null);
  const searchRef = useRef(null);

  const onSubmit = (ev) => {
    if (typeof window !== 'undefined') {
      navigate(`/anazitisi?term=${ev.search}`);
    }
    close();
  };

  useEffect(() => {
    let mounted = true;
    if (isOpen) {
      setTimeout(() => {
        mounted && searchRef.current.focus();
      }, 250);
    }
    return () => {
      mounted = false;
    };
  }, [isOpen]);

  return (
    <div className={`${styles.container}${isOpen ? ' ' + styles.isOpen : ''}`}>
      <header className={styles.header}>
        <Logo
          className={styles.logo}
          fill="#fff"
          aria-label="Minoera Traditional Cretan Jewelry"
        />
        <button
          className={styles.close}
          onClick={close}
          aria-label="Κλείσιμο αναζήτησης"
        >
          <IconContext.Provider value={{ color: '#fff', size: '32px' }}>
            <FiX />
          </IconContext.Provider>
        </button>
      </header>
      <main className={styles.main}>
        <form
          role="search"
          onSubmit={handleSubmit(onSubmit)}
          className={styles.form}
        >
          <label htmlFor="search" className={styles.label}>
            <button type="submit" className={styles.searchButton}>
              <IconContext.Provider value={{ color: '#fff', size: '40px' }}>
                <FiSearch />
              </IconContext.Provider>
            </button>
            <input
              ref={(e) => {
                register(e, {
                  required:
                    'Το πεδίο είναι υποχρεωτικό για να γίνει η αναζήτηση.',
                  minLength: {
                    value: 3,
                    message:
                      'Η αναζήτηση πρέπει να περιέχει τουλάχιστον 3 χαρακτήρες',
                  },
                });
                searchRef.current = e;
              }}
              type="search"
              name="search"
              id="search"
              placeholder="Αναζήτηση προϊόντων"
              aria-label="Αναζήτηση προϊόντων - ρούχα, ενδύματα, αξεσουάρ, προσφορές"
              className={styles.search}
            />
            {errors.search && (
              <ErrorMessage style={{ color: '#fff', background: 'rgba()' }}>
                {errors.search.message}
              </ErrorMessage>
            )}
          </label>
        </form>
        <div className={styles.popularContainer}>
          <h2 className={styles.popularTitle}>ΔΗΜΟΦΙΛΕΙΣ ΑΝΑΖΗΤΗΣΕΙΣ</h2>
          <div className={styles.popularLinks}>
            {terms && Array.isArray(terms) && terms.length > 0
              ? terms.map(({ term }) => (
                  <Link
                    key={term}
                    className={styles.popularLink}
                    to={`/anazitisi?term=${term}`}
                  >
                    {term}
                  </Link>
                ))
              : defaultSearches.map(({ term }) => (
                  <Link
                    key={term}
                    className={styles.popularLink}
                    to={`/anazitisi?term=${term}`}
                  >
                    {term}
                  </Link>
                ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default SearchOverlay;
