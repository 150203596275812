import React from 'react';
import { FiSearch } from 'react-icons/fi';

import useToggle from 'hooks/useToggle';

import { container, icon } from './SearchWidget.module.scss';
import { menuIconBtn } from 'styles/components/buttons.module.scss';

import SearchOverlay from './SearchOverlay';

const SearchWidget = () => {
  const { value, toggle } = useToggle();

  return (
    <div className={container}>
      <button
        onClick={toggle}
        className={menuIconBtn}
        aria-label="Αναζήτησε προϊόντα, χειροποίητα κρητικά κοσμήματα"
      >
        <FiSearch className={icon} />
      </button>
      <SearchOverlay isOpen={value} close={toggle} />
    </div>
  );
};

export default SearchWidget;
