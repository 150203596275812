import { useState } from 'react';

export default function useToggle(initialValue = false) {
  const [value, setValue] = useState(initialValue);

  const toggle = () => {
    setValue((current) => !current);
  };

  const toggleExact = (value) => {
    setValue(value);
  };

  return { value, toggle, toggleExact };
}
