import { Link } from 'gatsby';
import React from 'react';

import User from 'components/Icons/Project/Account';

import { useAuthUser } from 'utilities/AuthUser';

import { icon, initials } from './MenuUser.module.scss';

const MenuUser = () => {
  const { isLoggedIn, user } = useAuthUser();
  if (!isLoggedIn) {
    return (
      <Link
        className={icon}
        to="/app/account"
        aria-label="Δημιουργία λογαριασμού"
      >
        <User />
      </Link>
    );
  } else {
    return (
      <Link
        to="/app/profile"
        aria-label="Ο λογαριασμός μου"
        className={initials}
      >
        <span>{`${user.firstname ? user.firstname[0].toUpperCase() : 'N'}${
          user.lastname ? user.lastname[0].toUpperCase() : 'N'
        }`}</span>
      </Link>
    );
  }
};

export default MenuUser;
