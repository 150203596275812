import { Link, useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { FaPinterest } from 'react-icons/fa';

import Fb from 'components/Icons/Fb';
import Insta from 'components/Icons/Insta';
import Logo from 'components/Icons/LogoCentered';

import * as styles from './Widgets.module.scss';

const query = graphql`
  query Widgets {
    allStrapiCategory(
      filter: { enabled: { eq: true }, visibleToMainMenu: { eq: true } }
      sort: { fields: mainMenuIndex, order: ASC }
    ) {
      nodes {
        title
        slug
        strapiParent {
          id
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        tel
        email
        fullAddress
        fb
        instagram
        pinterest
      }
    }
  }
`;

const Widgets = () => {
  const {
    allStrapiCategory: { nodes: items },
    site: { siteMetadata: info },
  } = useStaticQuery(query);
  let menu = items.filter((item) => item.strapiParent === null);

  return (
    <div className={styles.widgetContainer}>
      <div className={styles.widgetInner}>
        <Link
          aria-label={`${info.title} - ${info.description}`}
          className={styles.brand}
          to="/"
        >
          <Logo fill="#fff" aria-label={info.title} />
        </Link>
        <div className={styles.container}>
          <div className={styles.column}>
            <ul className={styles.usefulLinks}>
              {menu.map((item) => (
                <li key={item.slug}>
                  <Link to={`/${item.slug}`} className={styles.link}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.column}>
            <ul className={styles.usefulLinks}>
              <li>
                <Link to={'/app/account'} className={styles.link}>
                  ΣΥΝΔΕΣΗ
                </Link>
              </li>
              <li>
                <Link to={'/app/account'} className={styles.link}>
                  ΔΗΜΙΟΥΡΓΙΑ ΛΟΓΑΡΙΑΣΜΟΥ
                </Link>
              </li>
              <li>
                <Link to={'/app/history'} className={styles.link}>
                  ΙΣΤΟΡΙΚΟ ΠΑΡΑΓΓΕΛΙΩΝ
                </Link>
              </li>
              <li>
                <Link to={'/custom-creations'} className={styles.link}>
                  ΕΙΔΙΚΕΣ ΠΑΡΑΓΓΕΛΙΕΣ
                </Link>
              </li>
              <li>
                <Link to={'/epikoinonia'} className={styles.link}>
                  ΕΠΙΚΟΙΝΩΝΙΑ
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.column}>
            <ul className={styles.usefulLinks}>
              <li>
                <Link to={'/etaireia'} className={styles.link}>
                  Η ΕΤΑΙΡΕΙΑ
                </Link>
              </li>
              <li>
                <Link to={'/oroi-xrhshs'} className={styles.link}>
                  ΟΡΟΙ ΧΡΗΣΗΣ
                </Link>
              </li>
              <li>
                <Link to={'/tropoi-plhromhs'} className={styles.link}>
                  ΤΡΟΠΟΙ ΠΛΗΡΩΜΗΣ
                </Link>
              </li>
              <li>
                <Link to={'/tropoi-apostolhs'} className={styles.link}>
                  ΤΡΟΠΟΙ ΑΠΟΣΤΟΛΗΣ
                </Link>
              </li>
              <li>
                <Link to={'/asfaleia-synallagwn'} className={styles.link}>
                  ΑΣΦΑΛΕΙΑ ΣΥΝΑΛΛΑΓΩΝ
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.column}>
            <p>{info.fullAddress}</p>
            <p>{info.tel}</p>
            <p>
              <a className={styles.link} href="mailto:minoera.pap@gmail.com">
                {info.email}
              </a>
            </p>
            <div className={styles.socialContainer}>
              <a
                href={info.fb}
                target="_blank"
                rel="noreferrer"
                className={styles.logo}
                aria-label={`${info.title} facebook page`}
              >
                <Fb fill={'#fff'} />
              </a>
              <a
                href={info.instagram}
                target="_blank"
                rel="noreferrer"
                className={styles.logo}
                aria-label={`${info.title} instagram account`}
              >
                <Insta fill={'#fff'} />
              </a>
              <a
                href={info.pinterest}
                target="_blank"
                rel="noreferrer"
                className={styles.logo}
                aria-label={`${info.title} pinterest`}
              >
                <FaPinterest size="1.25rem" fill={'#fff'} />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.paymentsContainer}>
          <div className={styles.paymentsImageContainer}>
            <StaticImage
              src="../../images/payment-methods-horizontal.png"
              alt="Alpha e-commerce payment methods, credit cards. Visa, mastercard, maestro, amex, masterpass"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Widgets;
